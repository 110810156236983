import * as React from 'react';

import { PostType } from '@blockworks/platform/api/strapi';
import { cn } from '@blockworks/ui/utils';

import ResearchCard from '@/components/pages/frontpage/components/research/research-card';

type ResearchSectionProps = {
    className?: string;
    posts: PostType[];
};

const ResearchSection = (props: ResearchSectionProps) => {
    const { className, posts } = props;

    return (
        <>
            <div
                className={cn(
                    'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 overflow-auto flex-grow-0 flex-shrink-0 w-full relative gap-10 p-10',
                    className,
                )}
            >
                {posts.map(post => (
                    <ResearchCard
                        key={`research-card-${post.slug}`}
                        title={post.title || ''}
                        slug={post.slug}
                        summary={post.excerpt || ''}
                        imageUrl={post.featuredImage?.url}
                        author={post.primaryAuthor?.name}
                        date={post.publishedAtTimestamp}
                    />
                ))}
            </div>
        </>
    );
};

export default ResearchSection;
