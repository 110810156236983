import * as React from 'react';
import { useEffect, useState } from 'react';

import { RenderSimpleHtml } from '@blockworks/ui/legacy/string';
import { cn } from '@blockworks/ui/utils';

import Metric from '@/components/pages/frontpage/components/feature/right/key-metrics/metric';

type KeyMetricsProps = {
    title: string;
    className?: string;
    sponsor?: string;
};

type MetricType = {
    name: string;
    cap: string;
    link?: string;
};

const KeyMetrics = (props: KeyMetricsProps) => {
    const { title, className, sponsor } = props;
    const [metrics, setMetrics] = useState<MetricType[]>([
        { name: 'Total Crypto Market Cap', cap: '' },
        { name: 'Bitcoin Market Cap', cap: '' },
        { name: 'Ethereum Market Cap', cap: '' },
        { name: 'ETH/BTC Ratio', cap: '' },
        { name: 'Ethereum Gas Price', cap: '' },
        { name: 'DeFi TVL', cap: '' },
        { name: 'Fear and Greed Index', cap: '' },
        { name: 'Federal Reserve Balance Sheet', cap: '' },
    ]);
    const links: {
        [key: string]: any;
    } = {
        'Total Crypto Market Cap': '/prices',
        'Bitcoin Market Cap': '/price/btc',
        'Ethereum Market Cap': '/price/eth',
    };

    useEffect(() => {
        fetch('https://blockworks-api.vercel.app/api/v1/metric')
            .then(res => {
                res.json().then((jsonResult: any) => {
                    if (jsonResult.metric) {
                        setMetrics(jsonResult.metric as MetricType[]);
                    }
                });
            })
            .catch(() => {
                console.error('Could not fetch from ticker API.');
            });
    }, []);

    return (
        <div className={cn('flex flex-col justify-start items-center min-h-[20rem]', className)}>
            <div className="flex justify-between items-start relative px-10 py-5 border-b border-gray-200 w-full">
                <p className="text-xs uppercase font-semibold">{title}</p>
                {sponsor && (
                    <div className="flex justify-start items-center relative gap-2">
                        <p className="text-[8px] lowercase text-light-gray">sponsored by</p>
                        <RenderSimpleHtml html={sponsor} />
                    </div>
                )}
            </div>
            <div className="flex flex-col justify-center items-center self-stretch px-10 pt-5 pb-10">
                <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0">
                    {metrics.map(metric => (
                        <Metric
                            key={metric.name}
                            name={metric.name}
                            value={metric.cap || ''}
                            link={links[metric.name]}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default KeyMetrics;
