import EditorialConfig from '@/editorial-config';
import Podcast from '@/types/pages/api/podcasts/podcast';

const fetchPodcasts = async (): Promise<Podcast[]> => {
    try {
        const res = await fetch(`${EditorialConfig.SITE_URL}/api/podcasts`, {
            method: 'GET',
        });

        if (res.status === 200) {
            return await res.json();
        }

        return [];
    } catch (err) {
        console.error('fetchPodcasts error', err);
        return [];
    }
};

export default fetchPodcasts;
