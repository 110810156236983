import { PostType } from '@blockworks/platform/api/strapi';

import { withExceptionCatcher } from '@/components/hoc/with-exception-catcher';
import FrontPageLayout from '@/components/pages/frontpage/layout/front-page-layout';
import type { GlobalOptionProps } from '@/types/_app';
import type { ArticleType, Event, WebinarStripped } from '@/types/article-type';
import type Podcast from '@/types/pages/api/podcasts/podcast';
import type { Category } from '@/utils/functions/wordpress/fetch-categories';

export interface FrontPageControllerProps {
    readMoreArticles: ArticleType[];
    featuredPosts: ArticleType[];
    researchArticles: PostType[];
    categories: Category[];
    podcasts: Podcast[];
    events: (WebinarStripped | Event)[];
    options: GlobalOptionProps;
}

export const FrontPageController = ({
    readMoreArticles,
    featuredPosts,
    researchArticles,
    categories,
    podcasts,
    events,
    options,
}: FrontPageControllerProps) => {
    return (
        <FrontPageLayout
            researchPosts={researchArticles}
            featuredArticles={featuredPosts}
            articles={readMoreArticles}
            categories={categories || []}
            podcasts={podcasts}
            events={events}
            options={options}
        />
    );
};

export default withExceptionCatcher(FrontPageController);
