import { useMemo } from 'react';

import { blurHashToDataURL } from '@blockworks/platform/utils';

import { PlaceholderImage } from '@/types/article-type';

const useBlurhashPlaceholder = (blurhash?: string | null): PlaceholderImage => {
    const blurDataURL = useMemo(() => {
        if (blurhash) {
            try {
                return blurHashToDataURL(blurhash, 32, 18);
            } catch (e) {
                return undefined;
            }
        }
        return undefined;
    }, [blurhash]);

    return blurDataURL ? { blurDataURL, placeholder: 'blur' } : { placeholder: 'empty' };
};

export default useBlurhashPlaceholder;
