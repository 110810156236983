import * as React from 'react';

import { PostType } from '@blockworks/platform/api/strapi';
import { cn } from '@blockworks/ui/utils';

import SectionHeader from '@/components/display/headers/section-header';
import EventsAndWebinarsSection from '@/components/pages/frontpage/components/events/events-and-webinars-section';
import Left from '@/components/pages/frontpage/components/feature/left';
import Middle from '@/components/pages/frontpage/components/feature/middle';
import Right from '@/components/pages/frontpage/components/feature/right';
import NewsSection from '@/components/pages/frontpage/components/news/news-section';
import PodcastsSection from '@/components/pages/frontpage/components/podcasts/podcasts-section';
import ResearchSection from '@/components/pages/frontpage/components/research/research-section';
import type { GlobalOptionProps } from '@/types/_app';
import type { ArticleType, WebinarStripped } from '@/types/article-type';
import { Event } from '@/types/article-type';
import type Podcast from '@/types/pages/api/podcasts/podcast';
import type { Category } from '@/utils/functions/wordpress/fetch-categories';

type FrontPageProps = {
    researchPosts: PostType[] | null;
    featuredArticles: ArticleType[];
    articles: ArticleType[] | null;
    categories: Category[];
    podcasts: Podcast[];
    events: (WebinarStripped | Event)[];
    options: GlobalOptionProps;
};

const FrontPageLayout = (props: FrontPageProps) => {
    const { researchPosts, featuredArticles, articles, categories, podcasts, events, options } = props;

    const headlineArticles = articles?.slice(0, 14) || [];
    const newsRecirculationArticles = articles?.filter(a => !featuredArticles.map(f => f.id).includes(a.id)) || [];

    return (
        <main>
            <section className="flex flex-row flex-wrap xl:flex-nowrap overflow-hidden divide-x">
                <div className="order-2 lg:order-1 h-full w-full lg:basis-1/3 xl:basis-1/4">
                    {articles && (
                        <Left
                            articles={headlineArticles}
                            researchPosts={researchPosts?.slice(0, 2) || []}
                            title="latest news"
                        />
                    )}
                </div>
                <div className="order-1 lg:order-2 lg:basis-2/3 xl:basis-1/2 h-full">
                    <Middle title="featured" articles={featuredArticles} />
                </div>
                <div className="xl:basis-1/4 order-3">
                    <Right
                        keyMetrics={{ title: 'Key metrics' }}
                        keyMetricSponsor={options?.keyMetricSponsor ?? ''}
                        featuredResearchPost={
                            researchPosts && researchPosts.length > 0 ? researchPosts!.slice(0, 1)[0]! : null
                        }
                        featuredArticle={featuredArticles.length === 6 ? featuredArticles[5] : null}
                    />
                </div>
            </section>
            <section>
                <SectionHeader
                    className="border-b border-t"
                    title="news"
                    description="Breaking headlines across our core coverage categories."
                    linkTitle={
                        <span className="flex align-middle">
                            more from news
                            <svg
                                width={8}
                                height={4}
                                viewBox="0 0 8 4"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="ml-1 mt-1.5"
                                preserveAspectRatio="none"
                            >
                                <path d="M6.005 1.5H0V2.5H6.005V4L8 2L6.005 0V1.5Z" fill="#5637CD" />
                            </svg>
                        </span>
                    }
                    linkUrl="/news"
                />
                {articles && (
                    <NewsSection
                        articles={newsRecirculationArticles}
                        excludePostIds={[
                            ...featuredArticles.map(a => Number(a.id)),
                            ...articles.map(a => Number(a.id)),
                        ]}
                        categoryTabs={categories.filter(c => c.count > 0)}
                    />
                )}
            </section>
            <section>
                <SectionHeader
                    className="border-b border-t"
                    title="Events & Roundtables"
                    linkTitle={
                        <>
                            <span className="align-middle flex sm:hidden">
                                Calendar
                                <svg
                                    width={10}
                                    height={10}
                                    viewBox="0 0 10 10"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="ml-1 mt-1.5"
                                    preserveAspectRatio="xMidYMid meet"
                                >
                                    <path
                                        d="M3.22222 2.37788V0.600098M6.77778 2.37788V0.600098M2.77778 4.15565H7.22222M1.88889 8.6001H8.11111C8.60203 8.6001 9 8.20213 9 7.71121V2.37788C9 1.88696 8.60203 1.48899 8.11111 1.48899H1.88889C1.39797 1.48899 1 1.88696 1 2.37788V7.71121C1 8.20213 1.39797 8.6001 1.88889 8.6001Z"
                                        stroke="#5637CD"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </span>
                            <span className="align-middle hidden sm:flex">
                                Events Calendar
                                <svg
                                    width={10}
                                    height={10}
                                    viewBox="0 0 10 10"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="ml-1 mt-1.5"
                                    preserveAspectRatio="xMidYMid meet"
                                >
                                    <path
                                        d="M3.22222 2.37788V0.600098M6.77778 2.37788V0.600098M2.77778 4.15565H7.22222M1.88889 8.6001H8.11111C8.60203 8.6001 9 8.20213 9 7.71121V2.37788C9 1.88696 8.60203 1.48899 8.11111 1.48899H1.88889C1.39797 1.48899 1 1.88696 1 2.37788V7.71121C1 8.20213 1.39797 8.6001 1.88889 8.6001Z"
                                        stroke="#5637CD"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </span>
                        </>
                    }
                    linkUrl="/events"
                />
                <EventsAndWebinarsSection events={events} />
            </section>
            <section>
                <SectionHeader
                    className="border-b border-t"
                    title="podcasts"
                    description="Your favorite crypto and macro podcasts in one place."
                    linkTitle={
                        <span className="flex align-middle">
                            explore the network
                            <svg
                                width={8}
                                height={4}
                                viewBox="0 0 8 4"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="ml-1 mt-1.5"
                                preserveAspectRatio="none"
                            >
                                <path d="M6.005 1.5H0V2.5H6.005V4L8 2L6.005 0V1.5Z" fill="#5637CD" />
                            </svg>
                        </span>
                    }
                    linkUrl="/podcasts"
                />
                <div className="relative w-full md:h-[445pt]">
                    <PodcastsSection
                        podcasts={podcasts}
                        className={cn(
                            // 'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4',
                            'touch-scroll', // only scroll via touchpad
                            'md:absolute md:left-0 md:right-0 flex flex-col md:flex-row overflow-x-auto px-8 w-full',
                        )}
                    />
                </div>
            </section>
            <section>
                <SectionHeader
                    className="border-b border-t"
                    title="research"
                    description="Insights you can't invest without."
                    linkTitle={
                        <span className="flex align-middle">
                            more research
                            <svg
                                width={8}
                                height={4}
                                viewBox="0 0 8 4"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="ml-1 mt-1.5"
                                preserveAspectRatio="none"
                            >
                                <path d="M6.005 1.5H0V2.5H6.005V4L8 2L6.005 0V1.5Z" fill="#5637CD" />
                            </svg>
                        </span>
                    }
                    linkUrl="https://blockworksresearch.com"
                />
                {researchPosts !== null && <ResearchSection posts={researchPosts} />}
            </section>
        </main>
    );
};

export default FrontPageLayout;
