import * as React from 'react';
import Image from 'next/image';

import { RenderSimpleHtml } from '@blockworks/ui/legacy/string';
import { cn } from '@blockworks/ui/utils';

import ByLine from '@/components/pages/frontpage/components/feature/middle/by-line';

type ResearchCardProps = {
    title: string;
    category?: string | null;
    slug: string;
    summary: string;
    imageUrl?: string | null;
    author?: string | null;
    date?: number | null;
    className?: string;
};

const ResearchCard = (props: ResearchCardProps) => {
    const { title, category, slug, summary, imageUrl, author, date, className } = props;

    return (
        <div className={cn('lg:w-[300px] border', className)}>
            {imageUrl && (
                <div>
                    <div className="w-full overflow-hidden">
                        <a
                            href={`https://app.blockworksresearch.com/research/${slug}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Image
                                className="object-cover h-32 w-full"
                                src={imageUrl}
                                alt={title}
                                width={300}
                                height={300}
                            />
                        </a>
                    </div>
                </div>
            )}
            <div className="gap-4 p-5">
                <div className="gap-6">
                    <div className="gap-2">
                        <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1">
                            <p className="flex-grow-0 flex-shrink-0 text-[10px] text-left uppercase text-primary">
                                {category || 'Blockworks Research'}
                            </p>
                        </div>
                        <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 relative gap-2.5">
                            <a
                                href={`https://app.blockworksresearch.com/research/${slug}`}
                                className="py-1.5 font-semibold text-xl text-left text-dark hover:text-primary"
                                target="_blank"
                                rel="noreferrer"
                            >
                                {title}
                            </a>
                        </div>
                        <div className="flex justify-center items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-2.5">
                            <RenderSimpleHtml
                                className="flex-grow text-sm font-thin text-left text-light-gray"
                                html={summary}
                            />
                        </div>
                        {author && date && (
                            <div className="py-4">
                                <ByLine
                                    authors={[{ title: author, id: 0, slug }]}
                                    publishedAt={new Date(date * 1000).toUTCString()}
                                    className="text-xxs"
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResearchCard;
