import * as React from 'react';

import { DateFormat } from '@blockworks/ui/date-time';
import { cn } from '@blockworks/ui/utils';

import EventCard from '@/components/display/cards/event-card';
import { Event, WebinarStripped } from '@/types/article-type';

type EventsSectionProps = {
    className?: string;
    events: (WebinarStripped | Event)[];
};

const EventsAndWebinarsSection = (props: EventsSectionProps) => {
    const { className, events } = props;

    return (
        <>
            <div
                className={cn(
                    'grid grid-cols-1 md:grid-cols-2 flex-grow-0 flex-shrink-0 w-full relative gap-10 p-10',
                    events.length === 4 ? 'xl:grid-cols-4' : 'xl:grid-cols-3',
                    className,
                )}
            >
                {events.map(event => {
                    const eventEndDate = (event as Event).eventEndDate ?? null;

                    return (
                        <EventCard
                            key={event.slug}
                            title={event.title}
                            type={event.type}
                            dateString={
                                event.type === 'webinar' ? (
                                    <DateFormat date={(event as WebinarStripped).date! ?? 0} />
                                ) : (
                                    (event as Event).customFields?.text_date || ''
                                )
                            }
                            startDate={event.eventStartDate}
                            endDate={eventEndDate}
                            description={event.excerpt || event.content}
                            location={
                                (event as Event).customFields?.location || (event as WebinarStripped).location || ''
                            }
                            learnMoreLink={event.permalink}
                            registerLink={
                                (event as Event).customFields?.registration_button_link?.url ||
                                (event as Event).customFields?.header_button_1_link?.url ||
                                (event as WebinarStripped).registrationButtonUrl ||
                                (event as WebinarStripped).headerButtonUrl ||
                                null
                            }
                        />
                    );
                })}
            </div>
        </>
    );
};

export default EventsAndWebinarsSection;
