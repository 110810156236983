import * as React from 'react';

import { PostType } from '@blockworks/platform/api/strapi';

import AdContainer from '@/components/ads/ad-container';
import AdContainerIds from '@/components/ads/ad-container-ids';
import AdWrapper from '@/components/ads/ad-wrapper';
import SubFeaturedArticleCard from '@/components/pages/frontpage/components/feature/middle/sub-featured-article-card';
import FeaturedPodcast from '@/components/pages/frontpage/components/feature/right/featured-podcast';
import KeyMetrics from '@/components/pages/frontpage/components/feature/right/key-metrics';
import Newsletter from '@/components/pages/frontpage/components/feature/right/newsletter';
import ResearchTeaser from '@/components/pages/frontpage/components/feature/right/research-teaser';
import type { ArticleType } from '@/types/article-type';
import type Podcast from '@/types/pages/api/podcasts/podcast';
import subscribeToNewsletter from '@/utils/functions/subscribe-to-newsletter';

type SectionDefinition = {
    title: string;
    children?: JSX.Element | React.ReactNode;
};

type RightProps = {
    keyMetrics: SectionDefinition;
    keyMetricSponsor: string;
    featuredResearchPost: PostType | null;
    featuredPodcast?: Podcast | null;
    featuredArticle?: ArticleType | null;
};

const Right = (props: RightProps) => {
    const { keyMetrics, featuredResearchPost, featuredPodcast, featuredArticle, keyMetricSponsor } = props;

    return (
        <div className="flex flex-col md:flex-row xl:flex-col border-t border-gray-200 xl:border-0 items-baseline md:items-stretch xl:items-baseline self-stretch">
            <div className="w-full md:w-1/2 xl:w-full flex flex-col md:flex-col-reverse	xl:flex-col bg-white border-gray-200 grow border-b md:border-b-0 md:border-t-1 xl:border-t-0 xl:border-b border-r-0">
                <KeyMetrics
                    title={keyMetrics.title}
                    sponsor={keyMetricSponsor}
                    className="w-full grow  md:border-r-0 md:border-t xl:border-t-0"
                />
                <AdWrapper className="hidden xl:flex border-t p-6 w-full">
                    <AdContainer googleAd={AdContainerIds.HomepageDesktop300x250} />
                </AdWrapper>
                <Newsletter
                    title="Newsletter"
                    subTitle="Blockworks Daily"
                    description="Get the daily newsletter that helps thousands of investors understand the markets."
                    inputPlaceholder="Email address"
                    buttonText="subscribe"
                    onButtonClick={subscribeToNewsletter}
                    className="border-t md:border-t-0 xl:border-t md:grow xl:shrink md:justify-center grow items-center flex flex-col"
                />
            </div>
            <div className="flex flex-col justify-start self-stretch bg-white border-l xl:border-l-0 md:w-1/2 xl:w-full ">
                {featuredArticle && (
                    <div className="w-full p-10">
                        <SubFeaturedArticleCard article={featuredArticle} showImage />
                    </div>
                )}

                {featuredResearchPost && (
                    <ResearchTeaser headerTitle="Research" article={featuredResearchPost} className="w-full p-10" />
                )}

                {featuredPodcast && (
                    <FeaturedPodcast
                        title="featured podcast"
                        podcastTitle=""
                        podcastUrl={`/podcast/${featuredPodcast.slug}`}
                        podcastShowTitle={`${featuredPodcast.title}`}
                        podcastShowDescription={`${featuredPodcast.summary}`}
                        podcastShowUrl=""
                        rssUrl={featuredPodcast.feedUrl}
                        itunesUrl={featuredPodcast.itunesIdentifier}
                        spotifyUrl={featuredPodcast.spotifyIdentifier}
                        youtubeUrl=""
                        className="border-t md:border-t-0 lg:border-t border-b-0 w-full"
                    />
                )}

                <AdWrapper className="flex xl:hidden border-t p-6">
                    <AdContainer googleAd={AdContainerIds.HomepageMobile300x250} />
                </AdWrapper>
            </div>
        </div>
    );
};

export default Right;
