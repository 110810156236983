import { useEffect, useState } from 'react';
import * as React from 'react';

import { cn } from '@blockworks/ui/utils';

import PodcastCard from '@/components/pages/frontpage/components/podcasts/podcast-card';
import type Podcast from '@/types/pages/api/podcasts/podcast';
import fetchPodcasts from '@/utils/functions/api/fetch-podcasts';

type PodcastsSectionProps = {
    className?: string;
    excludePodcasts?: string[];
    podcasts?: Podcast[];
};

const PodcastsSection = (props: PodcastsSectionProps) => {
    const { className, excludePodcasts, podcasts: initialPodcastsState } = props;
    const [podcasts, setPodcasts] = useState<Podcast[]>(initialPodcastsState ?? []);
    const exclude = excludePodcasts || [];

    useEffect(() => {
        if (podcasts.length > 0) {
            return;
        }

        fetchPodcasts().then(fetchedPodcasts => setPodcasts(fetchedPodcasts || []));
    }, []);

    return (
        <>
            <div className={cn('gap-10 p-10', className)}>
                {podcasts
                    .filter(p => !exclude.includes(p.slug))
                    .map(podcast => (
                        <PodcastCard
                            className="md:w-72"
                            imageClassName="h-32"
                            key={podcast.id}
                            title={podcast.title}
                            slug={podcast.slug}
                            summary={podcast.summary}
                            imageFile={podcast.imageFile}
                            itunesCategories={podcast.itunesCategories}
                            feedUrl={podcast.feedUrl}
                            spotifyIdentifier={podcast.spotifyIdentifier}
                            stitcherIdentifier={podcast.stitcherIdentifier}
                            itunesIdentifier={podcast.itunesIdentifier}
                        />
                    ))}
            </div>
        </>
    );
};

export default PodcastsSection;
