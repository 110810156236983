import * as React from 'react';

import { ShortRelativeTime } from '@blockworks/ui/date-time';
import { Link } from '@blockworks/ui/legacy/link';
import { cn } from '@blockworks/ui/utils';

import { getTimestampInSeconds } from '@/utils/functions/time/get-time-stamp-in-seconds';

type LatestHeadlineType = {
    publishedAt: string;
    headline: string;
    href: string;
    tag?: string | React.ReactElement;
    hideTimestamp?: boolean;
};

/**
 * Only show relative time if it's within threshold (defaults to the last 12 hours).
 * @param secondsDelta
 * @param threshold
 */
export const showRelativeTime = (secondsDelta: number, threshold: number = 43200): boolean => {
    return secondsDelta < threshold;
};

const LatestHeadline = (props: LatestHeadlineType) => {
    const { publishedAt, tag, headline, href, hideTimestamp } = props;

    const tzAwarePublishedAt = new Date(publishedAt).getTime() / 1000;

    return (
        <section className="flex gap-3 py-5 w-full">
            <div className={cn('item-center py-1.5 w-1/12', hideTimestamp ? 'hidden' : 'flex')}>
                {publishedAt && showRelativeTime(getTimestampInSeconds() - tzAwarePublishedAt) && (
                    <p className="text-xs uppercase text-primary">
                        <ShortRelativeTime date={tzAwarePublishedAt} />
                    </p>
                )}
            </div>
            <div className={cn('flex items-center', !hideTimestamp ? 'w-11/12' : 'w-full')}>
                <Link
                    href={href}
                    rel={tag === 'research' ? 'nofollow' : undefined}
                    target={tag === 'research' ? '_blank' : undefined}
                >
                    {tag !== 'post' && (
                        <span
                            className={cn(
                                'uppercase mr-1 text-xxs',
                                tag === 'research' && 'text-primary',
                                tag === 'sponsored' && 'text-blue-500',
                            )}
                        >
                            <>{tag}</>
                        </span>
                    )}
                    <span className="text-sm font-semibold font-headline visited:text-gray-700 hover:text-primary whitespace-normal">
                        {headline}
                    </span>
                </Link>
            </div>
        </section>
    );
};

export default LatestHeadline;
