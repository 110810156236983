import { cn } from '@blockworks/ui/utils';

type AdWrapperProps = {
    className?: string;
    children?: JSX.Element | React.ReactNode;
};

const AdWrapper = ({ children, className }: AdWrapperProps) => (
    <div className={cn('ad-wrapper flex place-content-center overflow-hidden', className)}>{children}</div>
);

export default AdWrapper;
