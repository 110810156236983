import * as React from 'react';

import { PostType } from '@blockworks/platform/api/strapi';
import { Link } from '@blockworks/ui/legacy/link';

import LatestHeadline, { showRelativeTime } from '@/components/pages/frontpage/components/feature/latest-headline';
import type { ArticleType } from '@/types/article-type';
import { getTimestampInSeconds } from '@/utils/functions/time/get-time-stamp-in-seconds';

type LeftProps = {
    title: string;
    researchPosts: PostType[];
    articles: ArticleType[];
};

type HeadlineType = {
    title: string;
    type: string;
    slug: string;
    publishedAtTimestamp: number;
    publishedAt: string;
};

const getPostLink = (post: HeadlineType): string => {
    const { type, slug } = post;

    if (!slug) return '';

    if (type === 'research') {
        return `https://app.blockworksresearch.com/research/${slug}`;
    }

    return `/news/${slug}`;
};

const Left = (props: LeftProps) => {
    const { researchPosts, articles, title } = props;

    const headlines: HeadlineType[] = [...researchPosts, ...articles]
        .map(p => {
            if ('publishedAt' in p) {
                return {
                    title: p.title ?? '',
                    slug: p.slug ?? '',
                    publishedAt: p.publishedAt || '',
                    publishedAtTimestamp: p.publishedAtTimestamp || 0,
                    type: (p as ArticleType).categories?.includes('Sponsored') ? 'sponsored' : (p.type ?? ''),
                };
            }
            return {
                title: p.title ?? '',
                slug: p.slug ?? '',
                publishedAt: '',
                publishedAtTimestamp: p.publishedAtTimestamp || 0,
                type: p.type ?? '',
            };
        })
        .sort((a, b) =>
            a?.type === 'sponsored'
                ? (a?.publishedAtTimestamp || 0) * -1
                : (b?.publishedAtTimestamp || 0) - (a?.publishedAtTimestamp || 0),
        );

    // Calculate if it is necessary to show timestamps in headlines
    const hideTimestamps =
        headlines.filter(
            h => h.publishedAtTimestamp && showRelativeTime(getTimestampInSeconds() - h.publishedAtTimestamp),
        ).length === 0;

    return (
        <>
            <div className="gap-2.5 px-10 py-5 border-b w-full">
                <p className="text-xs uppercase font-semibold">
                    <Link href="/news" prefetch={false}>
                        {title}
                    </Link>
                </p>
            </div>
            <div className="gap-10 px-10 py-5 divide-y divide-gray-200 w-full">
                {headlines?.map(post => (
                    <LatestHeadline
                        key={post.slug}
                        publishedAt={post.publishedAt}
                        headline={post.title || ''}
                        href={getPostLink(post)}
                        tag={post.type}
                        hideTimestamp={hideTimestamps}
                    />
                ))}
            </div>
        </>
    );
};

export default Left;
