import * as React from 'react';

import FeaturedArticleCard from '@/components/pages/frontpage/components/feature/middle/featured-article-card';
import SubFeaturedArticleCard from '@/components/pages/frontpage/components/feature/middle/sub-featured-article-card';
import type { ArticleType } from '@/types/article-type';

type LatestHeadlineType = {
    title: string;
    articles: ArticleType[] | null;
};

const Middle = (props: LatestHeadlineType) => {
    const { title, articles } = props;
    const featured = articles && articles.length > 0 ? articles[0]! : null;

    return (
        <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0">
            <div className="hidden sm:flex gap-2.5 px-4 md:px-10 py-5 border-b border-gray-200 w-full">
                <p className="text-xs uppercase font-semibold">{title}</p>
            </div>
            <div className="flex flex-col justify-start items-start gap-4 md:gap-6 lg:gap-10 px-4 md:px-10 py-4 md:py-6 lg:py-8">
                {featured && <FeaturedArticleCard article={featured} />}
                {articles &&
                    articles.length > 2 &&
                    articles.slice(1, 3).map(article => (
                        <div key={article.slug} className="flex justify-center items-start self-stretch gap-3">
                            <SubFeaturedArticleCard key={article.slug} article={article} showImage={false} />
                        </div>
                    ))}

                <div className="flex flex-col lg:flex-row justify-start items-start self-stretch gap-4 md:gap-6 lg:gap-10">
                    {articles &&
                        articles.length >= 3 &&
                        articles
                            .slice(3, 5)
                            .map(article => <SubFeaturedArticleCard key={article.slug} article={article} showImage />)}
                </div>
            </div>
        </div>
    );
};

export default Middle;
