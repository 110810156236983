import * as React from 'react';

import { Link } from '@blockworks/ui/legacy/link';
import { RenderSimpleHtml } from '@blockworks/ui/legacy/string';
import { cn } from '@blockworks/ui/utils';

import { ImageWithFallback } from '@/components/common';
import { NextImage } from '@/types/next-image';

type EventCardProps = {
    className?: string;
    title: string;
    type?: string;
    location: string;
    dateString: JSX.Element | string;
    startDate: Date | string | null;
    endDate: Date | string | null;
    description: string;
    registerLink?: string | null;
    registerText?: string;
    learnMoreLink?: string;
    learnMoreText?: string;
    imageUrl?: string | null;
    image?: NextImage | null;
};

const EventCard = (props: EventCardProps) => {
    const {
        className,
        type,
        title,
        location,
        startDate,
        dateString,
        description,
        learnMoreLink,
        learnMoreText,
        registerText,
        registerLink,
        imageUrl,
        image,
    } = props;

    return (
        <div className={cn('flex flex-col justify-start items-start flex-grow gap-3', className)}>
            <div className="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 gap-3">
                <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 gap-2">
                    <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1">
                        <p className="text-xxs text-left uppercase text-primary">{type || 'event'}</p>
                    </div>
                </div>
                {image && (
                    <div>
                        <Link href={learnMoreLink || ''}>
                            <ImageWithFallback
                                alt=""
                                src={image.url}
                                width={image.dimensions.width || 500}
                                height={image.dimensions.height || 280}
                                fallbackSrc={imageUrl || ''}
                                className="w-full h-full bg-gray-50"
                            />
                        </Link>
                    </div>
                )}
                <div className="flex justify-start relative gap-2.5">
                    <p className="flex-grow">
                        <Link href={learnMoreLink || ''} className="text-xl font-semibold text-dark hover:text-primary">
                            {title}
                        </Link>
                    </p>
                </div>
                <div className="flex flex-wrap justify-start items-center flex-grow-0 flex-shrink-0 relative gap-2">
                    {location && (
                        <div className="flex justify-start items-center gap-1">
                            <svg
                                width={7}
                                height={8}
                                viewBox="0 0 7 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="flex-grow-0 flex-shrink-0"
                                preserveAspectRatio="none"
                            >
                                <path
                                    d="M5.55228 4.93339C5.05014 5.43553 3.66667 6.81901 3.66667 6.81901C3.66667 6.81901 2.3203 5.47264 1.78105 4.93339C0.73965 3.89199 0.73965 2.20355 1.78105 1.16215C2.82245 0.120754 4.51089 0.120754 5.55228 1.16215C6.59368 2.20355 6.59368 3.89199 5.55228 4.93339Z"
                                    stroke="#111827"
                                    strokeWidth="0.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M4.66667 3.04777C4.66667 3.60006 4.21895 4.04777 3.66667 4.04777C3.11438 4.04777 2.66667 3.60006 2.66667 3.04777C2.66667 2.49549 3.11438 2.04777 3.66667 2.04777C4.21895 2.04777 4.66667 2.49549 4.66667 3.04777Z"
                                    stroke="#111827"
                                    strokeWidth="0.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1">
                                <p className="flex-grow-0 flex-shrink-0 text-[10px] text-left uppercase text-dark">
                                    {location}
                                </p>
                            </div>
                        </div>
                    )}
                    {location && dateString && (
                        <svg
                            width={2}
                            height={15}
                            viewBox="0 0 2 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="self-stretch flex-grow-0 flex-shrink-0"
                            preserveAspectRatio="none"
                        >
                            <line
                                x1="1.0835"
                                y1="14.6001"
                                x2="1.0835"
                                y2="0.600098"
                                stroke="#B4B4B4"
                                strokeOpacity="0.35"
                                strokeWidth="0.5"
                            />
                        </svg>
                    )}
                    {dateString && (
                        <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1">
                            <svg
                                width={7}
                                height={7}
                                viewBox="0 0 7 7"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="flex-grow-0 flex-shrink-0"
                                preserveAspectRatio="none"
                            >
                                <path
                                    d="M2.00016 1.93343V0.600098M4.66683 1.93343V0.600098M1.66683 3.26676H5.00016M1.00016 6.6001H5.66683C6.03502 6.6001 6.3335 6.30162 6.3335 5.93343V1.93343C6.3335 1.56524 6.03502 1.26676 5.66683 1.26676H1.00016C0.631973 1.26676 0.333496 1.56524 0.333496 1.93343V5.93343C0.333496 6.30162 0.631973 6.6001 1.00016 6.6001Z"
                                    stroke="#110B29"
                                    strokeWidth="0.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1">
                                <p className="flex-grow-0 flex-shrink-0 text-[10px] text-left uppercase text-dark">
                                    <>{dateString}</>
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="flex justify-start items-start self-stretch gap-2.5">
                <RenderSimpleHtml html={description} className="flex-grow text-sm text-left font-light text-dark" />
            </div>
            {startDate !== null && new Date(startDate) > new Date() && (
                <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 gap-3">
                    {registerLink && (
                        <a
                            href={registerLink}
                            className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-0.5 text-primary hover:text-black"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1">
                                <p className="flex-grow-0 flex-shrink-0 text-xs text-left uppercase">
                                    {registerText || 'register'}
                                </p>
                            </div>
                            <svg
                                width={10}
                                height={11}
                                viewBox="0 0 10 11"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="flex-grow-0 flex-shrink-0 w-2.5 h-2.5 relative"
                                preserveAspectRatio="xMidYMid meet"
                            >
                                <path
                                    d="M6.67067 5.18341H1.6665V6.01674H6.67067V7.26674L8.33317 5.60008L6.67067 3.93341V5.18341Z"
                                    fill="#5637CD"
                                />
                            </svg>
                        </a>
                    )}
                    {learnMoreLink && (
                        <Link
                            href={learnMoreLink}
                            className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-0.5 text-primary hover:text-black"
                        >
                            <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1">
                                <p className="flex-grow-0 flex-shrink-0 text-xs text-left uppercase">
                                    {learnMoreText || 'learn more'}
                                </p>
                            </div>
                            <svg
                                width={10}
                                height={11}
                                viewBox="0 0 10 11"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="flex-grow-0 flex-shrink-0 w-2.5 h-2.5 relative"
                                preserveAspectRatio="xMidYMid meet"
                            >
                                <path
                                    d="M6.67067 5.18341H1.6665V6.01674H6.67067V7.26674L8.33317 5.60008L6.67067 3.93341V5.18341Z"
                                    fill="#5637CD"
                                />
                            </svg>
                        </Link>
                    )}
                </div>
            )}
        </div>
    );
};

export default EventCard;
